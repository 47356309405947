import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../util/reactIntl';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import SearchResultsPanel from '../SearchResultsPanel/SearchResultsPanel';

import css from './NoSearchResultsMaybe.module.css';

const NoSearchResultsMaybeComponent = props => {
  const { listingsAreLoaded, totalItems, location, resetAll, listings } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <FormattedMessage id="SearchPage.noResults" />
      <br />
      {hasSearchParams ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          <FormattedMessage id={'SearchPage.resetAllFilters'} />
        </button>
      ) : null}
      <p>
        <FormattedMessage id="SearchPage.tryOtherSearchTerms" />
      </p>
      <SearchResultsPanel
        className={css.searchListingsPanel}
        listings={listings}
        pagination={null}
        isMapVariant={false}
      />
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const {
          featuredListingIDs,
        } = state.SearchPage;
  const listings = getListingsById(state, featuredListingIDs);

  return {
    listings,
  };
};

const NoSearchResultsMaybe = compose(
  connect(mapStateToProps)
)(NoSearchResultsMaybeComponent);

export default NoSearchResultsMaybe;
